import WebGLView from "./webgl/WebGLView";
import GUIView from "./gui/GUIView";
import "./../sass/style.scss";
export default class App {
  constructor() {}

  init() {
    this.initWebGL();
    this.initGUI();
    this.addListeners();
    this.animate();
    this.resize();
    this.getWebsiteInfo();
  }

  getWebsiteInfo() {
    const apiKey =
      "14b845921b9fd4cc00fd479d6f68923b22a312961d465b45e26b518d061526a027bc70a8304ffbd72f29ce1f70e1321ddddf14c3c94bd2f78ffbb53989f70a5a5c20ddf5a9169dd9390f879f8ab32afa4868342f5470d0b3e2a7d2433e2c980f92006d65be8d51e7188ee58e41f8c6bb6f54a3c65ef5c511278967975fbabd9b";
    const apiUrl = "http://87.106.224.148:1337/api/website-info";

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        var elements = document.getElementsByClassName("firstName");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.firstName;
        }
        var elements = document.getElementsByClassName("lastName");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.lastName;
        }
        var elements = document.getElementsByClassName("email");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.email;
        }
        var elements = document.getElementsByClassName("phone");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.phone;
        }
        var elements = document.getElementsByClassName("about");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.about;
        }
        var elements = document.getElementsByClassName("copyright");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.copyright;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  initWebGL() {
    this.webgl = new WebGLView(this);
    if (document.querySelector(".face"))
      document
        .querySelector(".face")
        .appendChild(this.webgl.renderer.domElement);
  }

  initGUI() {
    this.gui = new GUIView(this);
  }

  addListeners() {
    this.handlerAnimate = this.animate.bind(this);

    window.addEventListener("resize", this.resize.bind(this));
    window.addEventListener("keyup", this.keyup.bind(this));

    const el = this.webgl.renderer.domElement;
    el.addEventListener("click", this.click.bind(this));
  }

  animate() {
    this.update();
    this.draw();

    this.raf = requestAnimationFrame(this.handlerAnimate);
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  update() {
    if (this.gui.stats) this.gui.stats.begin();
    if (this.webgl) this.webgl.update();
    if (this.gui) this.gui.update();
  }

  draw() {
    if (this.webgl) this.webgl.draw();
    if (this.gui.stats) this.gui.stats.end();
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize() {
    if (this.webgl) this.webgl.resize();
  }

  keyup(e) {
    // g
    if (e.keyCode == 71) {
      if (this.gui) this.gui.toggle();
    }
  }

  click(e) {
    this.webgl.next();
  }
}
